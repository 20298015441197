import React from "react";

import { Box, Flex, Heading, IconButton, Tooltip } from "@chakra-ui/core";

export function CardWrapper({ children }) {
  return (
    <Box
      mt={5}
      p={5}
      minW="sm"
      w="40vw"
      maxW="md"
      shadow="md"
      borderWidth="1px"
      rounded="lg"
    >
      {children}
    </Box>
  );
}

export function EditableCard({
  heading,
  nextToButton,
  onEditClick,
  label,
  children,
}) {
  return (
    <CardWrapper>
      <Flex justify="space-between" align="center">
        {" "}
        <Heading mb="2" fontSize="xl">
          {heading}
        </Heading>
        <Flex align="center" justify="flex-end">
          {nextToButton}
          <Tooltip placement="top" label={label} showDelay="800">
            <IconButton
              aria-label={label}
              icon="edit"
              variant="ghost"
              onClick={onEditClick}
            />
          </Tooltip>
        </Flex>
      </Flex>
      {children}
    </CardWrapper>
  );
}

export default function Card({ heading, nextToHeading, children }) {
  return (
    <CardWrapper>
      <Flex justify="space-between" align="center">
        {" "}
        <Heading mb="2" fontSize="xl">
          {heading}
        </Heading>
        <Flex align="center" justify="flex-end">
          {nextToHeading}
        </Flex>
      </Flex>
      {children}
    </CardWrapper>
  );
}
