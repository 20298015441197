import React from "react";

import { Flex, Heading, Text, Button } from "@chakra-ui/core";

import steps from "./flowSteps";

// User is waiting to get through on the ticketing page.
function WaitingPage({ step, setStep }) {
  return (
    <Flex minH="50vh" justify="center" align="center" direction="column">
      {step === steps.WAIT ? (
        <Heading color="gray.600" px="auto">
          Good Luck!
        </Heading>
      ) : (
        <>
          <Heading color="gray.800" px="auto">
            Well done!
          </Heading>
          <Heading as="h2" color="gray.800" px="auto">
            Keep trying for other lucky puddlers.
          </Heading>
        </>
      )}
      <Button
        mt="10"
        mx="auto"
        variantColor="teal"
        size="lg"
        display="block"
        onClick={() => {
          setStep(steps.DETAILS);
        }}
      >
        I'm through, reveal!
      </Button>
      <Text mt="10" color="gray.600">
        Click me when the Glasto page asks for you and your friend's details.
      </Text>
    </Flex>
  );
}

export default WaitingPage;
