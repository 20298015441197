import React, { useState, useEffect, useContext } from "react";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import {
  Box,
  Flex,
  PseudoBox,
  Heading,
  Text,
  Icon,
  IconButton,
  Spinner,
} from "@chakra-ui/core";

import UserBox from "../common/UserBox";

import { SavingStatusBox } from "../common/SavingStatusBox";

import { getRankings, setRankings } from "../api";

import { UserContext } from "../context";
import { SearchBox } from "./SearchBox";

const DragHandle = sortableHandle(({ visibility }) => {
  return (
    <Box pb={1} visibility={visibility} cursor="grab">
      <Icon name="drag-handle" />
    </Box>
  );
});

function RankedUser({ index, user, isDragable = false, handleClick }) {
  let bg = "white";
  let handle = <DragHandle />;
  let height = 12;
  let icon = "small-close";
  if (!isDragable) {
    bg = "gray.100";
    height = 8;
    handle = <DragHandle visibility="hidden" />;
    icon = "small-add";
  }

  return (
    <PseudoBox
      as="div"
      display="flex"
      alignItems="center"
      height={height}
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="grey.500"
      bg={bg}
      px="2"
      role="group"
    >
      <Text borderRightWidth="1px" fontSize="lg" pr="1" mr="1">
        {index}
      </Text>
      {handle}
      <UserBox user={user} />

      <PseudoBox
        ml="auto"
        as="div"
        display={isDragable ? "none" : "block"}
        _groupHover={{ display: "block" }}
      >
        <IconButton
          variant="ghost"
          variantColor="blue"
          aria-label="add to priority list"
          icon={icon}
          onClick={() => handleClick(user)}
          height={height}
          width={height}
        />
      </PseudoBox>
    </PseudoBox>
  );
}

const SortableItem = SortableElement(({ user, quePos, handleClick }) => (
  <RankedUser user={user} isDragable index={quePos} handleClick={handleClick} />
));

const SortableList = SortableContainer(({ rankings, handleClick }) => {
  return (
    <Box
      borderWidth="1px"
      borderStyle="solid"
      borerColor="grey.500"
      bg="gray.300"
      minH={10}
    >
      {rankings.map((user, index) => (
        <SortableItem
          key={user.id}
          index={index}
          quePos={index + 1}
          user={user}
          handleClick={handleClick}
        />
      ))}
    </Box>
  );
});

function ManualRankingTable({ rankings, onSortEnd, onItemRemove }) {
  const _onSortEnd = ({ oldIndex, newIndex }) => {
    onSortEnd(arrayMove(rankings, oldIndex, newIndex));
  };

  return (
    <Box>
      <SortableList
        rankings={rankings}
        onSortEnd={_onSortEnd}
        handleClick={onItemRemove}
        lockAxis="y"
        useDragHandle
      />
    </Box>
  );
}

function AutoRankingTable({ rankings, onItemRemove, indexOffset }) {
  const listItems = rankings.map((u, index) => (
    <RankedUser
      user={u}
      key={u.id}
      //index={index + indexOffset}
      handleClick={onItemRemove}
    />
  ));
  return (
    <Box bg="gray.300" minH={5} maxHeight="400px" overflowY="auto">
      {listItems}
    </Box>
  );
}

// Helper Functions
function extractUserIds(users) {
  return users.reduce((acc, user) => {
    acc.push(user.id);
    return acc;
  }, []);
};

const filterUserArray = (users, usersToRemove) => {
  const uids = extractUserIds(usersToRemove);
  return users.reduce((acc, user) => {
    if (!uids.includes(user.id)) {
      acc.push(user);
    }
    return acc;
  }, []);
};

//

function RankingTable({ isEditing, allUsers }) {
  const { user } = useContext(UserContext);

  const [manualRankings, setManualRankings] = useState(null);
  const [allRankings, setAllRankings] = useState(null); // The full list
  const [autoRankings, setAutoRankings] = useState([]);

  const loading = !allRankings;

  const [savingStatus, setSavingStatus] = useState("done");
  const [isDirty, setIsDirty] = useState(false); // true if ranking needs saving

  // Timers used to control the saving of the manual ranking list
  const [debounceTimer, setDebounceTimer] = useState(0);
  const [popupTimer, setPopupTimer] = useState(0);

  const onManualItemsSortEnd = (newManualRankings) => {
    setManualRankings(newManualRankings);
    setIsDirty(true);
  };

  const onManualItemDelete = (user) => {
    // remove user from manual rankings state array.

    const index = manualRankings.findIndex((u) => u.id === user.id);
    const newManualRankings = [...manualRankings];
    newManualRankings.splice(index, 1);
    setManualRankings(newManualRankings);
    setIsDirty(true);

    // Reload the auto array
    setAutoRankings(filterUserArray(allRankings, newManualRankings));
  };

  const onManualItemAdd = (user) => {
    // add the user to manual Rankings state array. (using wrapper function)
    setManualRankings((manualRankings) => manualRankings.concat(user));

    // remove user from auto rankings state array.
    setAutoRankings((autoRankings) => {
      const index = autoRankings.findIndex((u) => u.id === user.id);
      const newList = [...autoRankings];
      newList.splice(index, 1);
      return newList;
    });
    setIsDirty(true);
  };

  const onSearchFilter = (searchFilter) => {
    //remove all users from the manual table
    const remainingUsers = filterUserArray(allUsers, manualRankings);
    const filteredUsers = remainingUsers
      .filter((user) => {
        return new RegExp(searchFilter, "i").test(user.name);
      })
    setAutoRankings(filteredUsers);
  };

  useEffect(() => {
    async function fetchData() {
      const result = await getRankings(user.id, user.key);
      if (result[0] === "ok") {
        setManualRankings(result[1].manual);
        const newAllRankings = result[1].all;
        setAllRankings(newAllRankings);
        setAutoRankings(filterUserArray(newAllRankings, result[1].manual));
      }
    }
    async function saveData() {
      const uidsToSave = extractUserIds(manualRankings);
      const result = await setRankings(user.id, user.key, uidsToSave);
      if (result === "ok") {
        setSavingStatus("saved");

        setPopupTimer(setTimeout(() => setSavingStatus("done"), 2000)); //
      } else {
        setSavingStatus("error");
      }
    }

    if (loading) {
      fetchData();
    }
    if (isDirty && savingStatus !== "saving") {
      clearTimeout(popupTimer);
      clearTimeout(debounceTimer);
      setIsDirty(false);
      console.log("setting debounce timeout");
      setSavingStatus("unsaved");
      setDebounceTimer(
        setTimeout(() => {
          setSavingStatus("saving");
          console.log("about to save");
          saveData();
        }, 4000)
      );
    }



    return () => {
      clearTimeout(popupTimer);
      clearTimeout(debounceTimer);
    };
  }, [
    loading,
    isDirty,
    savingStatus,
    debounceTimer,
    popupTimer,
    manualRankings,
    user.id,
    user.key,
  ]);

  // dirty hack to update the status of the users in the manual rankings
  // check that manualRankings is not null and a list
  if (manualRankings && manualRankings.length > 0) {
    manualRankings.forEach((user) => {
      const allUser = allUsers.find((u) => u.id === user.id);
      if (allUser) {
        user.status = allUser.status;
      }
    });
  }
  // same for auto rankings
  if (autoRankings && autoRankings.length > 0) {
    autoRankings.forEach((user) => {
      const allUser = allUsers.find((u) => u.id === user.id);
      if (allUser) {
        user.status = allUser.status;
      }
    });
  }

  if (loading) {
    return (
      <Flex py={10} justify="center" align="center">
        <Spinner mx="auto" size="xl" />
      </Flex>
    );
  } else {
    return (
      <>
        <Heading color="gray:800" as="h3" size="sm" my="3">
          Your personal list
        </Heading>
        {isEditing ? (
          <>
            <ManualRankingTable
              rankings={manualRankings}
              onItemRemove={onManualItemDelete}
              onSortEnd={onManualItemsSortEnd}
            />
            <Heading as="h3" size="sm" my="3">
              The rest of the Puddle
            </Heading>
            <SearchBox onSearchFilter={onSearchFilter} />
            <AutoRankingTable
              rankings={autoRankings}
              onItemRemove={onManualItemAdd}
              indexOffset={manualRankings.length + 1}
            />
            <SavingStatusBox status={savingStatus} />
          </>
        ) : (
          <PersonalList rankings={manualRankings} />
        )}
      </>
    );
  }
}

export default RankingTable;

function PersonalList({ rankings }) {
  const entries = rankings.map((user, index) => (
    <Flex
      key={user.id}
      align="center"
      py="1"
      borderBottomWidth={index === 5 ? "1px" : "0px"}
    >
      <NumberBox>{index + 1}.</NumberBox>
      <UserBox user={user} />
    </Flex>
  ));
  entries.push(
    <Flex align="center" mt={1} key={"0000"}>
      <Text>... and other lucky puddlers to fill up your spaces!</Text>
    </Flex>
  );
  return <Box>{entries}</Box>;
}

function NumberBox({ children }) {
  return (
    <Box w="1em" mr={2}>
      {children}
    </Box>
  );
}
