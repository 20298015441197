import React, { useState, useEffect, useContext } from "react";

import {
  Alert,
  AlertIcon,
  Flex,
  Text,
  Button,
  Box,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  SlideIn,
  useClipboard,
} from "@chakra-ui/core";

import { useForm } from "react-hook-form";

import { UserContext } from "../context";

import { updateUserDetails, generateShareId } from "../api";

import { EditableCard } from "../common/Card";
import { SavingStatusBox } from "../common/SavingStatusBox";

function Feature({ title, content }) {
  return (
    <Flex wrap="wrap">
      <Text fontSize="md" fontWeight="bold">
        {title}:
      </Text>
      <Text ml="2" fontSize="md">
        {content}
      </Text>
    </Flex>
  );
}

function UserDetailsEditForm({ user, successCb }) {
  const [savingNotif, setSavingNotif] = useState("none");
  const cred = { userId: user.id, key: user.key };
  const {
    handleSubmit,
    errors,
    register,
    setError,
    clearError,
    formState,
  } = useForm();

  async function onSubmit(values) {
    setSavingNotif("none");
    const res = await updateUserDetails(cred, values);
    switch (res[0]) {
      case "ok":
        clearError("regId");
        successCb(res[1]);
        break;

      case "REGID_EXISTS":
        setError("regId", "validate", "This registration is already taken!");
        break;

      case "PARSE_ERROR":
        if (res[1].includes("regId")) {
          setError("regId", "validate", "Invalid registration id!");
          break;
        }
        setSavingNotif("error");
        break;
      default:
        setSavingNotif("error");
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isRequired isInvalid={Boolean(errors.name)}>
        <FormLabel htmlFor="name">Full Name</FormLabel>
        <Input name="name" defaultValue={user.name} ref={register} />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={Boolean(errors.regId)}>
        <FormLabel htmlFor="regId">Registration Id</FormLabel>
        <Input name="regId" defaultValue={user.regId} ref={register} />
        <FormErrorMessage>
          {errors.regId && errors.regId.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={Boolean(errors.postcode)}>
        <FormLabel htmlFor="postcode">Post code</FormLabel>
        <Input name="postcode" defaultValue={user.postcode} ref={register} />
        <FormErrorMessage>
          {errors.postcode && errors.postcode.message}
        </FormErrorMessage>
        <FormHelperText>
          Your details should match the ticketing website.
        </FormHelperText>
      </FormControl>
      <Flex justify="flex-end" alignItems="baseline">
        <SavingStatusBox status={savingNotif} />
        <Button
          mt={4}
          variantColor="blue"
          isLoading={formState.isSubmitting}
          type="submit"
        >
          Save
        </Button>
      </Flex>
    </form>
  );
}

function UserDetails({ navigate }) {
  const { user, updateUser } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);

  const [savingStatus, setSavingStatus] = useState("none");
  const [popupTimer, setPopupTimer] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure(); // For the Modal
  const btnRef = React.useRef();

  function toggleEdit() {
    setIsEditing(!isEditing);
  }

  function onSuccess(newUser) {
    setSavingStatus("saved");
    setPopupTimer(setTimeout(() => setSavingStatus("none"), 5000));
    setIsEditing(false);

    updateUser(newUser);
  }

  useEffect(() => {
    return () => {
      clearTimeout(popupTimer);
    };
  }, [popupTimer]);

  return (
    <EditableCard
      heading="Your Details"
      nextToButton=""
      onEditClick={toggleEdit}
      label="Change your details"
    >
      {isEditing && <UserDetailsEditForm user={user} successCb={onSuccess} />}

      {!isEditing && <Feature title="Name" content={user.name} isEditing />}
      {!isEditing && (
        <Feature title="Registration Id" content={user.regId} isEditing />
      )}
      {!isEditing && (
        <Feature title="Postcode" content={user.postcode} isEditing />
      )}

      {!isEditing && (
        <Flex justify="flex-end" alignItems="baseline">
          <Button
            rightIcon="external-link"
            variantColor="gray"
            variant="outline"
            onClick={onOpen}
            ref={btnRef}
          >
            Share
          </Button>
        </Flex>
      )}
      <SavingStatusBox status={savingStatus} />
      <ShareModal isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
    </EditableCard>
  );
}

function ShareModal({ isOpen, onClose, btnRef }) {
  return (
    <SlideIn in={isOpen}>
      {(styles) => (
        <Modal finalFocusRef={btnRef} isOpen={true} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Share Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight="bold">
                Don't give your registration details to people directly.{" "}
              </Text>
              <Text mt="2" mb="4">
                If you do, nobody else in the Puddle will know if you have been
                bought a ticket. Instead, give them your sharing link.
              </Text>
              <ShareLinkGenerator />
            </ModalBody>

            <ModalFooter>
              <Button variantColor="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </SlideIn>
  );
}

function ShareLinkGenerator() {
  const { user, updateUser } = useContext(UserContext);

  const [isGenerating, setIsGenerating] = useState(false);

  const [error, setError] = useState(false);

  const relShareLink = `/details/${user.shareId}`;
  const absShareLink = `https://puddletix.com/details/${user.shareId}`;

  const { onCopy, hasCopied } = useClipboard(absShareLink);

  function onGenerate() {
    setIsGenerating(true);

    async function generate() {
      const res = await generateShareId(user.id, user.key);
      setIsGenerating(false);
      switch (res[0]) {
        case "ok":
          setError(null);
          user.shareId = res[1];
          updateUser(user);
          break;
        default:
          setError("Failed to generate sharing link. Please try again.");
      }
    }
    generate();
  }

  if (user.shareId) {
    return (
      <>
        {" "}
        <Text color="gray.800" fontSize="sm">
          Link sharing is active:
        </Text>
        <Flex
          p="1"
          pl="3"
          my="2"
          borderWidth="1px"
          rounded="md"
          align="center"
          justify="space-between"
        >
          <Box color="blue.700" as="a" target="_blank" href={relShareLink}>
            {absShareLink}
          </Box>
          <Button onClick={onCopy} ml={2}>
            {hasCopied ? "Copied" : "Copy"}
          </Button>
        </Flex>
        <Text>
          Share the link with people who are not registered in the Puddle.
          Explain to them the importance of marking you as locked when they use
          your details. Encourage them to try it in advance.{" "}
        </Text>
      </>
    );
  }
  return (
    <>
      {" "}
      <Button
        variantColor="gray"
        d="flex"
        mx="auto"
        onClick={onGenerate}
        isLoading={isGenerating}
        loadingText="Generating"
      >
        Activate Link Sharing
      </Button>
      {error && (
        <Alert my="4" status="error" variant="left-accent">
          <AlertIcon />
          {error}
        </Alert>
      )}
    </>
  );
}

export default UserDetails;
