import React, { useState, useRef, useEffect } from "react";

import { Badge, Icon, Box } from "@chakra-ui/core";

export const ticketStatuses = {
  UNDEFINED: 0,
  WANTING: 1,
  LOCKED: 2,
  PURCHASED: 3,
  CONFIRMED: 4,
  HAS_TICKET: 5,
  NOT_WANTING: 6,
};

export function describeStatus({
  status,
  buyer,
  variant = "subtle",
  ...props
}) {
  if (!buyer) {
    buyer = "Somebody";
  }
  if (typeof status === "string") {
    status = parseInt(status);
  }

  let display = "block";
  if (props.d) {
    display = props.d;
  }

  let icon, badge, txt;
  switch (status) {
    case ticketStatuses.WANTING:
      txt =
        "You will be participating in the puddle and want people to buy tickets for you.";
      badge = (
        <Badge
          d={display}
          mx="1"
          variantColor="purple"
          variant={variant}
          {...props}
        >
          Wanting
        </Badge>
      );
      icon = <Icon d={display} name="hand-heart" color="purple.500" />;

      break;
    case ticketStatuses.LOCKED:
      badge = (
        <Badge d={display} mx="1" variantColor="orange" variant={variant}>
          Locked
        </Badge>
      );
      icon = <Icon d={display} name="lock" color="orange.500" />;
      txt = `${buyer} is in the process of buying a ticket for you.`;
      break;
    case ticketStatuses.PURCHASED:
      badge = (
        <Badge d={display} mx="1" variantColor="green" variant={variant}>
          Bought
        </Badge>
      );
      icon = <Icon d={display} name="check-circle" color="green.600" />;
      txt = `${buyer} has bought you a ticket but is waiting for the confirmation email.`;
      break;
    case ticketStatuses.CONFIRMED:
      badge = (
        <Badge d={display} mx="1" variantColor="green" variant={variant}>
          Confirmed
        </Badge>
      );
      icon = <Icon d={display} name="two-tick-filled" color="green.600" />;
      txt = `Your ticket has been confirmed by ${buyer}. Please pay them back.`;
      break;
    case ticketStatuses.PAYED:
      badge = (
        <Badge d={display} mx="1" variantColor="green" variant={variant}>
          Payed
        </Badge>
      );
      icon = <Icon d={display} name="two-tick-filled" color="green.600" />;
      txt = `You have a ticket. ${buyer} has confirmed you payed them back.`;
      break;
    case ticketStatuses.HAS_TICKET:
      badge = (
        <Badge d={display} mx="1" variantColor="green" variant={variant}>
          has ticket
        </Badge>
      );
      icon = <Icon d={display} name="check-circle" color="green.600" />;
      txt =
        "You already have a ticket and won't be participating in the Puddle.";
      break;
    case ticketStatuses.NOT_WANTING:
      badge = (
        <Badge d={display} mx="1" variantColor="gray" variant={variant}>
          Not wanting
        </Badge>
      );
      icon = <Icon d={display} name="not-allowed" color="gray.500" />;
      txt = "You do not want to participate in the Puddle.";
      break;
    default:
      badge = (
        <Badge d={display} mx="1" variantColor="red" variant={variant}>
          Undefined
        </Badge>
      );
      icon = <Icon d={display} name="warning" color="red.500" />;
      txt = "Your ticket status is unknown.";
  }
  return {
    icon,
    badge,
    description: txt,
  };
}

export function UserStatusBadge({ status, isOutlined, ...props }) {
  let desc;
  if (isOutlined) {
    desc = describeStatus({ status, variant: "outline", ...props });
  } else {
    desc = describeStatus({ status, ...props });
  }
  return desc.badge;
}

export function UserStatusCircle({ status, isSolid = false }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const timeoutRef = useRef();

  let desc;
  if (isSolid) {
    desc = describeStatus({ status });
  } else {
    desc = describeStatus({ status, variant: "outline" });
  }

  function toggle() {
    setIsExpanded(!isExpanded);
    clearTimeout(timeoutRef.current);
  }

  function leave() {
    clearTimeout(timeoutRef.current);

    const id = setTimeout(() => {
      setIsExpanded(false);
    }, 2000);

    timeoutRef.current = id;
  }

  function enter() {
    clearTimeout(timeoutRef.current);
  }

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  });

  let cursor = "default";
  if (!isExpanded) {
    cursor = "pointer";
  }

  return (
    <Box
      onClick={toggle}
      onMouseEnter={enter}
      onMouseLeave={leave}
      cursor={cursor}
    >
      {isExpanded ? desc.badge : desc.icon}
    </Box>
  );
}
