import React, { useState, useContext } from "react";

import { Text, Box, Switch, Icon, Tooltip } from "@chakra-ui/core";

import { confirmTicket } from "../api";
import { UserContext } from "../context";

import { ticketStatuses } from "../common/TicketStatus";
import { EditableCard } from "../common/Card";
import { SavingButton } from "../common/SavingStatusBox";
import UserBox from "../common/UserBox";
import DeleteButton from "./DeleteButton";

function BoughtForTable({ boughtFor, setBoughtFor, isEditing }) {
  const [warningShown, setWarningShown] = useState(false);

  function onConfirmedChange(e, userId) {
    const newBoughtFor = [...boughtFor];
    for (const u of newBoughtFor) {
      if (u.id === userId) {
        u.status = e.target.checked
          ? ticketStatuses.CONFIRMED
          : ticketStatuses.PURCHASED;
      }
    }
    setBoughtFor(newBoughtFor);
  }

  function onPaidChange(e, userId) {
    const newBoughtFor = [...boughtFor];
    for (const u of newBoughtFor) {
      if (u.id === userId) {
        u.hasPaid = e.target.checked;
      }
    }
    setBoughtFor(newBoughtFor);
  }

  function setWillDelete(bool, userId) {
    setWarningShown(true);
    const newBoughtFor = [...boughtFor];
    for (const u of newBoughtFor) {
      if (u.id === userId) {
        u.willDelete = bool;
      }
    }
    setBoughtFor(newBoughtFor);
  }

  const listItems = boughtFor.map((u) => {
    return (
      <tr key={u.id}>
        <Box as="td" textDecoration={u.willDelete ? "line-through" : "none"}>
          <UserBox user={u} />
        </Box>
        <Box as="td" textAlign="center">
          <Switch
            size="sm"
            isChecked={u.status === ticketStatuses.CONFIRMED}
            onChange={(e) => {
              onConfirmedChange(e, u.id);
            }}
            isDisabled={u.willDelete || !isEditing}
          />
        </Box>
        <Box as="td" textAlign="center">
          <Switch
            size="sm"
            isChecked={u.hasPaid}
            onChange={(e) => {
              onPaidChange(e, u.id);
            }}
            isDisabled={u.willDelete || !isEditing}
          />
        </Box>
        {isEditing && (
          <Box as="td" textAlign="center">
            <DeleteButton
              setWillDelete={(bool) => {
                setWillDelete(bool, u.id);
              }}
              willDelete={u.willDelete}
              showWarning={!warningShown}
            />
          </Box>
        )}
      </tr>
    );
  });

  return (
    <Box as="table" w="100%">
      <thead>
        <tr>
          <th>Name</th>

          <th>
            <Tooltip label="Confirmed?" showDelay="100" hideDelay="100">
              <Icon name="email" />
            </Tooltip>
          </th>
          <th>
            <Tooltip label="Paid you back?">
              <Text cursor="default">£</Text>
            </Tooltip>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>{listItems}</tbody>
    </Box>
  );
}

export default function BoughtFor() {
  const { user, updateUser } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const defaultBoughtFor = user.boughtFor.map((u) => Object.assign({}, u));
  const [boughtFor, setBoughtFor] = useState(defaultBoughtFor);

  function toggleEdit() {
    setIsEditing(!isEditing);
    if (isEditing) {
      setBoughtFor(defaultBoughtFor);
    }
  }

  async function saveStatus() {
    const res = await confirmTicket(user.id, user.key, boughtFor);
    if (res !== "error") {
      const newBoughtFor = res.filter((userRes) => {
        return userRes.status !== 1;
      });
      updateUser({ ...user, boughtFor: newBoughtFor });
      setBoughtFor(newBoughtFor);
      return true;
    } else {
      return false;
    }
  }

  let text = "";
  let hasBought = true;
  let lockedCount = 0;
  let boughtForCount = 0;
  for (const boughtforuser of user.boughtFor) {
    if (boughtforuser.status === ticketStatuses.LOCKED) {
      lockedCount++;
    } else if (
      boughtforuser.status === ticketStatuses.PURCHASED ||
      boughtforuser.status === ticketStatuses.CONFIRMED
    ) {
      boughtForCount++;
    }
  }
  if (lockedCount === 1) {
    text += "You have locked 1 person! ";
  } else if (lockedCount > 1) {
    text += `You have locked ${lockedCount} people! `;
  } else if (boughtForCount === 1) {
    text += "You have bought a ticket for 1 person.";
  } else if (boughtForCount > 1) {
    text += `You have bought tickets for ${boughtForCount} people!`;
  } else {
    text += "You have not bought tickets for anybody (yet).";
    hasBought = false;
  }
  return (
    <EditableCard
      heading="Bought For"
      onEditClick={toggleEdit}
      label="Edit bought for statuses"
    >
      <Text mb="2">{text}</Text>
      {hasBought && (
        <BoughtForTable
          boughtFor={isEditing ? boughtFor : user.boughtFor}
          setBoughtFor={setBoughtFor}
          isEditing={isEditing}
        />
      )}

      <SavingButton
        saveCb={saveStatus}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </EditableCard>
  );
}
