import React from "react";
import {
    Icon, Input,
    InputGroup,
    InputLeftElement,
} from "@chakra-ui/core";

export function SearchBox({ onSearchFilter }) {
    return (
        <InputGroup my={3}>
            <InputLeftElement
                color="gray.300"
                fontSize="1.2em"
                children={<Icon name="search" />} />
            <Input
                placeholder="Name or RegId"
                onChange={(event) => {
                    onSearchFilter(event.target.value);
                }} />
        </InputGroup>
    );
}
