import React from "react";
import { Box, Text } from "@chakra-ui/core";
export function MotivationArticle() {
  return (
    <Box as="article" mx="2" maxW="3xl">
      <Text my="3" p="3" pr="16" shadow="sm">
        Buying tickets for popular events can be stressful. Especially if you
        coordinate a large group of friends. Usually only a fraction of people
        get “through” to the event ticketing page but can then buy multiple
        tickets.{" "}
      </Text>
      <Text my="3" p="3" pl="16" shadow="sm">
        When you get through, you need to know who still needs tickets. No two
        people should enter the same details at the same time. They might both
        be kicked out. All of this is difficult to coordinate.
      </Text>
      <Text my="3" p="3" pr="16" shadow="sm">
        Small groups are easier to organise but bigger groups have a higher
        chance of being successful. In a big pool, or puddle as we call it,
        everybody joins forces. Even if you have a ticket you can keep trying
        for others. If you still need one, they will keep going for you.
        PuddleTix simplifies the communication so you can focus on the important
        bit: hitting F5.
      </Text>
    </Box>
  );
}
