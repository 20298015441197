import React from "react";
import { Flex, Text } from "@chakra-ui/core";

function Footer(props) {
  return (
    <Flex
      as="footer"
      justifyContent="center"
      padding="2rem"
      bg="gray.100"
      color="gray.500"
      textAlign="center"
    >
      <Text fontSize="xs">
        Feedback? Always welcome! <br />
        Contact me directly if you know me :)
      </Text>
    </Flex>
  );
}

export default Footer;
