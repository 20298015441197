import React, { useState, useEffect, useContext } from "react";

import {
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/core";

import ExternalDetails from "./ExternalDetails";

import {
  findPartialUserByShareId,
  getPartialUserByShareIdAndKey,
} from "../api";

import { UserContext } from "../context";

function ShareDetailsPage({ shareId }) {
  const localStorageShareKeyName = `shareKey-${shareId}`;
  const localStorageShareUIDName = `shareUID-${shareId}`;
  const { user } = useContext(UserContext);

  const [shownUser, setShownUser] = useState(null);

  const [alert, setAlert] = useState(null);

  const [shareKey, setShareKey] = useState(
    (user && user.id) ||
      localStorage.getItem(localStorageShareKeyName) ||
      "none"
  );
  const [shareUID, setShareUID] = useState(
    localStorage.getItem(localStorageShareUIDName) || "none"
  );

  useEffect(() => {
    async function fetchUser() {
      const result = await getPartialUserByShareIdAndKey(
        shareUID,
        shareId,
        shareKey
      );
      switch (result[0]) {
        case "ok":
          setAlert(null);
          setShownUser(result[1]);
          break;
        default:
          setAlert([
            "error",
            "Failed to load details!",
            "Check connection and try again.",
          ]);

          break;
      }
    }

    async function findUser() {
      const result = await findPartialUserByShareId(shareId, shareKey);
      switch (result[0]) {
        case "ok":
          setShownUser(result[1].user);
          setShareUID(result[1].user.id);
          setAlert(null);

          break;

        default:
          setAlert([
            "error",
            "Failed to load details!",
            "Check connection and try again.",
          ]);
      }
    }

    async function findUserAndKey() {
      const result = await findPartialUserByShareId(shareId);
      switch (result[0]) {
        case "ok":
          setShownUser(result[1].user);
          setShareKey(result[1].shareKey);
          setShareUID(result[1].user.id);

          setAlert(null);

          break;

        default:
          setAlert([
            "error",
            "Failed to load details!",
            "Check connection and try again.",
          ]);
      }
    }

    // need to load user data
    if (!shownUser) {
      if (shareKey === "none") {
        findUserAndKey();
      } else if (shareUID === "none") {
        findUser();
      } else {
        fetchUser();
      }
    }

    if (!user || !user.id) {
      localStorage.setItem(localStorageShareKeyName, shareKey);
    }
    localStorage.setItem(localStorageShareUIDName, shareUID);
    console.log(`shareKey used is ${shareKey}`);
  }, [
    shownUser,
    shareKey,
    shareUID,
    shareId,
    localStorageShareKeyName,
    localStorageShareUIDName,
    user,
  ]);

  return (
    <Flex
      minH="60vh"
      justify="center"
      align="center"
      direction="column"
      textAlign="center"
    >
      {!shownUser && !alert && <p>Loading {shareId}</p>}

      {shownUser && (
        <ExternalDetails
          shownUser={shownUser}
          setShownUser={setShownUser}
          shareKey={shareKey}
          shareId={shareId}
          shareUID={shareUID}
          setAlert={setAlert}
        />
      )}

      {alert ? (
        <Alert status={alert[0]} mt="5">
          <AlertIcon />
          <AlertTitle mr={2}>{alert[1]}</AlertTitle>
          <AlertDescription>{alert[2]}</AlertDescription>
        </Alert>
      ) : (
        <Alert mt="5" visibility="hidden">
          <AlertDescription>"hidden"</AlertDescription>
        </Alert>
      )}
    </Flex>
  );
}

export default ShareDetailsPage;
