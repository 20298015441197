import React, { useState } from "react";

import { Text, Select } from "@chakra-ui/core";

import { updateUserStatus } from "../api";

import { EditableCard } from "../common/Card";
import { SavingButton } from "../common/SavingStatusBox";

import { describeStatus, ticketStatuses } from "../common/TicketStatus";

export default function UserStatus({ user, setStatus }) {
  const [tmpStatus, setTmpStatus] = React.useState(ticketStatuses.WANTING);

  const [isEditing, setIsEditing] = useState(false);

  function toggleEdit() {
    setIsEditing(!isEditing);
    let newStatus = ticketStatuses.WANTING;
    if (
      user.status === ticketStatuses.NOT_WANTING ||
      user.status === ticketStatuses.HAS_TICKET
    ) {
      newStatus = user.status;
    }
    setTmpStatus(newStatus);
  }

  const statusDesc = describeStatus({
    status: user.status,
    buyer: user.buyer.name,
  });

  async function saveStatus() {
    if (
      (await updateUserStatus(
        { userId: user.id, key: user.key },
        tmpStatus
      )) === "ok"
    ) {
      setStatus(tmpStatus);
      return true;
    } else {
      return false;
    }
  }

  return (
    <EditableCard
      heading="Your Ticket Status"
      nextToButton={!isEditing && statusDesc.badge}
      onEditClick={toggleEdit}
      label="Change your ticket status"
    >
      {isEditing && (
        <Select
          my="2"
          onChange={(e) => setTmpStatus(e.target.value)}
          value={tmpStatus}
        >
          <option value={ticketStatuses.WANTING}>I want a ticket</option>
          <option value={ticketStatuses.NOT_WANTING}>
            I don't want a ticket
          </option>
          <option value={ticketStatuses.HAS_TICKET}>
            I already have a ticket
          </option>
        </Select>
      )}
      {isEditing ? (
        <Text>{describeStatus(tmpStatus).description}</Text>
      ) : (
        <Text>{statusDesc.description}</Text>
      )}

      <SavingButton
        saveCb={saveStatus}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </EditableCard>
  );
}
