import React, { useContext } from "react";

import { Box, Flex, Stack, Button } from "@chakra-ui/core";

import { UserContext } from "../context";

import Rankings from "./Rankings";
import UserDetails from "./UserDetails";
import UserStatus from "./UserStatus";
import BoughtFor from "./BoughtFor";
import AlertLocked from "../common/AlertLocked";
import Coaches from "./Coaches";

import { Partners } from "./Partners";

function Dashboard({ navigate }) {
  const { user, updateUser } = useContext(UserContext);

  function updateStatus(status) {
    const newUser = { ...user };
    newUser.status = status;
    updateUser(newUser);
  }

  return (
    <Box>
      <Flex justify="center" wrap="wrap">
        <Box m="4" p="4" maxW="xl">
          <Stack spacing={8}>
            <UserStatus user={user} setStatus={updateStatus} />
            <UserDetails user={user} setUser={updateUser} />
            <Coaches />
            <Partners />

          </Stack>
        </Box>
        <Box m="4" p="4" maxW="xl">
          <Stack spacing={8}>
            <Rankings />
            <BoughtFor user={user} />
          </Stack>
        </Box>
      </Flex>
      <Flex justify="center" p={5}>
        <Button
          variantColor="teal"
          size="lg"
          onClick={() => {
            navigate("/book");
          }}
        >
          Ready to buy tickets
        </Button>
      </Flex>
      {user && <AlertLocked boughtFor={user.boughtFor} />}
    </Box>
  );
}

export default Dashboard;
