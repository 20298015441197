import React, { useState, useContext, useEffect } from "react";

import { Box, Flex, Text } from "@chakra-ui/core";

import steps from "./flowSteps";

import WaitingPage from "./WaitingPage";
import DetailsPage from "./DetailsPage";

import { UserContext } from "../context";

function ProgressBoxIndicator({ name, isActive }) {
  return (
    <Text
      color={isActive ? "gray.800" : "gray.500"}
      fontWeight={isActive ? "bold" : "normal"}
      px="3"
    >
      {name}
    </Text>
  );
}

function ProgressIndicatorSVG({ children }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1.7em"
      viewBox="0 0 150.594 17.7075"
    >
      <path
        d="M12.5718 6.7626L7.1375 8.8844v-.9301l4.1734-1.512-4.1734-1.5121V4l5.4343 2.1218z"
        fill="#A0AEC0"
      />
      <path
        d="M141.0616 6.4486l2.839.0236c8.2476-.03 8.2476 10.711.061 10.7286-10.0399.0217-124.5205-.0177-138.1003-.009-7.1464-.009-7.1464-10.7496-.012-10.7496h5.8704"
        fill="none"
        stroke="#A0AEC0"
      />
    </svg>
  );
}

function ProgressBox({ step }) {
  return (
    <Flex
      justify="center"
      align="center"
      my="8"
      mx="auto"
      borderBottomWidth="2px"
      pb="3"
      maxW="2xl"
      position="relative"
    >
      <Flex justify="center" align="center">
        <ProgressBoxIndicator
          name="Wait"
          isActive={step === steps.WAIT || step === steps.BOUGHT}
        />
        <Text>{">"}</Text>
        <ProgressBoxIndicator name="Copy" isActive={step === steps.DETAILS} />
        <Text>{">"}</Text>
        <ProgressBoxIndicator name="Pay" isActive={step === steps.CONFIRM} />
      </Flex>
      <Box position="absolute" top="3px">
        <ProgressIndicatorSVG></ProgressIndicatorSVG>
      </Box>
    </Flex>
  );
}

// Main Booking Flow Component, maintains which step of the flow
// the user is on.
function BookingPages({ navigate }) {
  const { user } = useContext(UserContext);

  const [step, setStep] = useState(steps.WAIT);

  useEffect(() => {
    if (step === steps.WAIT && user.hasBoughtForLocked) {
      setStep(steps.DETAILS);
    }
  }, [step, user.hasBoughtForLocked]);

  let currentPage;
  switch (step) {
    case steps.WAIT:
    case steps.BOUGHT:
    default:
      currentPage = <WaitingPage step={step} setStep={setStep} />;
      break;

    case steps.DETAILS:
    case steps.CONFIRM:
      currentPage = <DetailsPage step={step} setStep={setStep} />;
      break;
  }

  return (
    <Box>
      <ProgressBox step={step} />
      {currentPage}
    </Box>
  );
}

export default BookingPages;
