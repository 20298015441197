import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context";
import { Box, Text, Flex, Spinner, Checkbox } from "@chakra-ui/core";
import { getCoachOptions, setCoaches } from "../api";
import Card, { EditableCard } from "../common/Card";
import { SavingStatusBox } from "../common/SavingStatusBox";
import { UserStatusBadge, ticketStatuses } from "../common/TicketStatus";

function Coaches() {
  const { user, updateUser } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [allCoaches, setAllCoaches] = useState(null); // The full list object
  const [manualCoaches, setManualCoaches] = useState([]); // array of ids

  const loading = !allCoaches;

  const [savingStatus, setSavingStatus] = useState("done");
  const [isDirty, setIsDirty] = useState(false); // true if ranking needs saving

  // Timers used to control the saving of the manual ranking list
  const [debounceTimer, setDebounceTimer] = useState(0);
  const [popupTimer, setPopupTimer] = useState(0);

  const onManualItemDelete = (coachId) => {
    // remove coach from manual coaches state array.
    const newManualCoaches = manualCoaches.filter(coach => coach !== coachId);
    setManualCoaches(newManualCoaches);
    setIsDirty(true);
  };

  const onManualItemAdd = (coachItem) => {
    // add the coach to manual Coaches state array. (using wrapper function)
    setManualCoaches((prevManualCoaches) => prevManualCoaches.concat(coachItem));
    setIsDirty(true);
  };

  function toggleEdit() {
    setIsEditing(!isEditing);
  }

  useEffect(() => {
    async function fetchData() {
      const options = await getCoachOptions();
      if (options[0] === "ok") {
        const coachData = options[1];
        setAllCoaches(coachData);
      }
      setManualCoaches(user.coaches);
    }
    async function saveData() {
      const savedUser = await setCoaches(user.id, user.key, manualCoaches);
      if (savedUser) {
        updateUser(savedUser);
        setSavingStatus("saved");

        setPopupTimer(setTimeout(() => setSavingStatus("done"), 2000));
      } else {
        setSavingStatus("error");
      }
    }

    if (loading) {
      fetchData();
    }
    if (isDirty && savingStatus !== "saving") {
      clearTimeout(popupTimer);
      clearTimeout(debounceTimer);
      setIsDirty(false);
      console.log("setting debounce timeout");
      setSavingStatus("unsaved");
      setDebounceTimer(
        setTimeout(() => {
          setSavingStatus("saving");
          console.log("about to save");
          saveData();
        }, 4000)
      );
    }

    return () => {
      clearTimeout(popupTimer);
      clearTimeout(debounceTimer);
    };
  }, [
    loading,
    isDirty,
    savingStatus,
    debounceTimer,
    popupTimer,
    manualCoaches,
    allCoaches,
    user.coaches,
    user.id,
    user.key,
    updateUser
  ]);

  if (loading) {
    return (
      <Flex py={10} justify="center" align="center">
        <Spinner mx="auto" size="xl" />
      </Flex>
    );
  } else if (allCoaches.active === false) {
    return (
      <Card heading="Coach Options" nextToHeading={"🚌"}>
        <Text color="gray.900" mt="3">
          Coach options are not available for general sale.
        </Text>
        <Text color="gray.500" mt="3">
          For coach ticket sales you will be able to select coaches you are willing to go on.
          If you select a coach, your details will not be shown to somebody who has selected different coaches to you.
        </Text>
      </Card>
    );
  } else {
    return (
      <EditableCard
        heading="Coach Options"
        nextToButton={(user.status === ticketStatuses.WANTING && manualCoaches.length === 0) ? "🚌 ❗" : "🚌"}
        onEditClick={toggleEdit}
        label="Edit your priority list"
      >
        <Text color="gray.500" mt="3">
          Select which coaches you want to go on. Hit edit to see available options. If you select a coach, your details will not be shown to somebody who has selected different coaches to you.
        </Text>
        {isEditing ? (
          <>
            <Box maxHeight={400} overflowY="auto" p={3}>
              {Object.entries(allCoaches.options).map((coach, index) => (
                <Box key={coach[0]}>

                  <Checkbox value={coach[0]}
                    key={coach[0]}
                    isChecked={manualCoaches.includes(coach[0])}
                    onChange={(e) => {
                      manualCoaches.includes(coach[0]) ? onManualItemDelete(coach[0]) : onManualItemAdd(coach[0]);
                    }}>
                    {coachToString(coach[1])}
                  </Checkbox>
                </Box>
              ))}
            </Box>
            <SavingStatusBox status={savingStatus} />
          </>
        ) : (
          <PersonalList coaches={user.coaches ?? []} allCoaches={allCoaches} />
        )
        }

      </EditableCard >
    );
  }
}

export default Coaches;

function coachToString(coach) {
  let dayStr = "";
  // add the day of the week
  if (coach.day) {
    // coach.day is of the format "2020-09-12"
    const date = new Date(coach.day);
    // output as 09-12 (Sat) so, MM-DD (Day)
    dayStr = `${date.toLocaleDateString("en-GB", { month: "2-digit" })}-${date.toLocaleDateString("en-GB", { day: "2-digit" })} (${date.toLocaleDateString("en-GB", { weekday: "short" })})`;

    return `${dayStr} ${coach.from}`;
  }
  return coach.from;
}


function PersonalList({ coaches, allCoaches }) {
  const coachOptions = allCoaches.options;
  const coachStrings = []
  for (const coachId of coaches) {
    const coach = coachOptions[coachId];

    if (!coach) {
      continue;
    }

    coachStrings.push(coachToString(coach));
  }
  // sort alphabetically
  coachStrings.sort();

  const entries = coachStrings.map((coach, index) => (
    <Flex
      key={index}
      align="center"
      py={0}
      my={0}
      borderBottomWidth={index === coaches.length - 1 ? "1px" : "0px"}
    >
      <NumberBox>-</NumberBox>
      <Text>{coach}</Text>
    </Flex>
  ))
  return (
    <>
      {entries.length > 0 ? (

        <Box><Text>You have selected the following coaches:</Text>{entries}</Box>
      ) : <Box pt="2" >
        You have not selected any coaches.
        This means you'd be happy to go on any coach!
        If you don't want a coach ticket, set your ticket status to
        <UserStatusBadge status={ticketStatuses.NOT_WANTING} isOutlined d="inline" />.</Box>}
    </>
  )
}

function NumberBox({ children }) {
  return (
    <Box w="1em" mr={2}>
      {children}
    </Box>
  );
}
