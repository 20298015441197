
import React, { } from "react";
import {
  Flex,
  Text,
} from "@chakra-ui/core";

function Banner() {
  switch (process.env.REACT_APP_ENV) {
    case 'sandbox':
      return (
        <Flex
          as="header"
          justify="center"
          padding={{ base: "3", md: "6" }}
          bg="red.700"
          align="center"
          color="white"

        >

          <Text><span role="img" aria-label="Sand+Umbrella">⛱️</span>You are playing in the sandbox. Try it out, but don't use it in earnest.</Text>

        </Flex>
      );
    case 'development':
      return (
        <Flex
          as="header"
          justify="center"
          padding={{ base: "2" }}
          bg="green.700"
          align="center"
          color="white"

        >

          <Text><span role="img" aria-label="Man coding">👨‍💻</span>
            This is a dev build.
            <span role="img" aria-label="Woman coding">👩‍💻</span></Text>

        </Flex>
      );
    case 'none':
      return (
        <Flex
          as="header"
          justify="center"
          padding={{ base: "2" }}
          bg="red.700"
          align="center"
          color="white"

        >
          <span as='span' role="img" aria-label="Rocket">🚀</span>
          <Text marginX={{ base: 2 }}>Launching soon.</Text>
          <span as='span' role="img" aria-label="Tada">🎉</span>

        </Flex>
      );
    default:
      return (null);
  }
}

export default Banner;
