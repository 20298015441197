import React from "react";

import {
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Tooltip,
} from "@chakra-ui/core";
import { UserStatusBadge, ticketStatuses } from "../common/TicketStatus";

export default function DeleteButton({
  setWillDelete,
  willDelete,
  showWarning,
}) {
  const [isOpen, setIsOpen] = React.useState();
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  function onClickDelete() {
    if (willDelete) {
      setWillDelete(false);
    } else if (showWarning) {
      setIsOpen(true);
    } else {
      setWillDelete(true);
    }
  }

  return (
    <>
      <Tooltip label="Remove from your list" showDelay="800">
        <IconButton
          aria-label="Remove user from bought for table"
          icon="delete"
          variantColor="red"
          size="sm"
          variant="ghost"
          onClick={onClickDelete}
          //{willDelete && {bg: "blue"}}
          bg={willDelete ? "red.100" : "transparent"}
        />
      </Tooltip>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remove from list
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? <br /> Doing so indicates that you have not bought
            them a ticket yet and sets their status back to:{" "}
            <UserStatusBadge
              status={ticketStatuses.WANTING}
              isOutlined
              d="inline"
            />{" "}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variantColor="red"
              onClick={() => {
                onClose();
                setWillDelete(true);
              }}
              ml={3}
            >
              Remove
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
