import React, { useContext } from "react";
import {
  Box,
  Heading,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormControl,
  Input,
  Button,
  Switch,
} from "@chakra-ui/core";

import styled from "@emotion/styled";

import { useForm } from "react-hook-form";

import { createUser } from "../api";

import { UserContext } from "../context";

import Faker from "faker/locale/en_GB";

function SignUpForm({ navigate, randomize = false }) {
  const { updateUser } = useContext(UserContext);
  const values = {
    name: randomize ? Faker.name.findName() : "",
    email: randomize ? Faker.internet.email() : "",
    postcode: randomize ? Faker.address.zipCode() : "",
    regId: randomize ? Faker.random.alphaNumeric(2) + Faker.random.number(1000) : "",
  };

  const {
    handleSubmit,
    errors,
    register,
    setError,
    clearError,
    formState
  } = useForm({ defaultValues: values });

  async function onSubmit(values) {
    const res = await createUser(values);
    switch (res[0]) {
      case "ok":
        console.log("logged in");
        clearError("regId");
        updateUser(res[1]);
        navigate("/dashboard");
        break;
      case "REGID_EXISTS":
        setError(
          "regId",
          "validate",
          "This registration is already taken by an existing user!"
        );
        break;
      default:
        console.log("Something unknown has gone wrong");
    }
  }

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const validateRegId = async (value) => {
    await sleep(100);
    // if value includes spaces
    if (value.includes(" ")) {
      setError("regId", "validate", "Remove any spaces from your registration Id (maybe from the end)?");
      return "RegId spaces";
    }
    if (!value.match(/^[0-9a-zA-Z-_]+$/)) {
      setError("regId", "validate", "Only letters, numbers, dashes and underscores are allowed");
      return "RegId invalid"
    }

    clearError("regId");
    return true;

  };

  const StyledFormLabel = styled(FormLabel)`
    margin-top: 1.2em;
  `;


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isRequired isInvalid={errors.name}>
        <FormLabel htmlFor="name">Full Name</FormLabel>
        <Input
          name="name"
          placeholder="name"
          ref={register}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
        <FormHelperText>
          Submit your full name as used by the ticketing website.
        </FormHelperText>
      </FormControl>

      <FormControl isInvalid={errors.email}>
        <StyledFormLabel htmlFor="email">Email</StyledFormLabel>
        <Input
          name="email"
          placeholder="join@puddle.com"
          ref={register({
            pattern: {
              value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
              message: "This email is not valid.",
            },
          })}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
        <FormHelperText>
          We may share your email with those who have bought a ticket for you.
        </FormHelperText>
      </FormControl>

      <FormControl isRequired isInvalid={errors.regId}>
        <StyledFormLabel htmlFor="regId">Registration Id</StyledFormLabel>
        <Input
          name="regId"
          placeholder="xxxxxxxxx"
          onBlur={(e) => validateRegId(e.target.value)}
          ref={register({ validate: validateRegId })}
        />
        <FormErrorMessage>
          {errors.regId && errors.regId.message}
        </FormErrorMessage>
        <FormHelperText>
          Submit your registration ID you were given by glastonbury
        </FormHelperText>
      </FormControl>

      <FormControl isRequired isInvalid={errors.postcode}>
        <StyledFormLabel htmlFor="postcode">Post code</StyledFormLabel>
        <Input
          name="postcode"
          placeholder="AB12 3CD"
          ref={register}
        />
        <FormErrorMessage>
          {errors.postcode && errors.postcode.message}
        </FormErrorMessage>
        <FormHelperText>
          The post code should match the details given by glastonbury.
        </FormHelperText>
      </FormControl>

      <FormControl isRequired isInvalid={errors.agreeSharing} paddingTop='3'>
        <FormLabel htmlFor="agreeSharing-rules">
          I won't share my registration details directly with people who are not part of the puddle. <br />
          I understand that doing so may cause others to loose out on tickets if puddleTix is not aware that I'm already being bought a ticket. <br />
          Instead, I will use the sharing link and explain to my friends how to use it.
        </FormLabel>
        <Switch
          name="agreeSharing"
          id="agreeSharing-rules"
          ref={register({ required: "You have to agree to the sharing rules" })}
        />
        <FormErrorMessage>
          {errors.agreeSharing && errors.agreeSharing.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={errors.agreePaying} paddingTop='3'>
        <FormLabel htmlFor="agreePaying-rules">
          I will pay the buyer of my ticket back the price of my reservation ASAP.
        </FormLabel>
        <Switch
          name="agreePaying"
          id="agreePaying-rules"
          ref={register({ required: "You have to agree to the paying rules" })}
        />
        <FormErrorMessage>
          {errors.agreePaying && errors.agreePaying.message}
        </FormErrorMessage>
      </FormControl>
      <Button
        mt={4}
        variantColor="blue"
        isLoading={formState.isSubmitting}
        type="submit"
        isDisabled={process.env.REACT_APP_ENV === 'none'}
      >
        Join
      </Button>
    </form>
  );
}

function SignUp({ preFill, navigate }) {
  return (
    <Box px={[4, 20, 48, 64]} paddingBottom={{ base: 4, md: 8 }} >
      <Heading py={10}>Join the Puddle!</Heading>
      <SignUpForm navigate={navigate} randomize={preFill === "random" && process.env.REACT_APP_ENV !== 'production'} />
    </ Box>
  );
}

export default SignUp;
