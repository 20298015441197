import React, { useState, useEffect, useContext } from "react";

import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  ButtonGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/core";

import { getDetails, confirmPurchase } from "../api";

import { UserContext } from "../context";

import steps from "./flowSteps";

import CopyableDetailsTable from "./CopyableDetailsTable";
import ConfirmableDetailsTable from "./ConfirmableDetailsTable";
import { ticketStatuses } from "../common/TicketStatus";

function DetailsLayout({ heading, helpText, table, coaches, error, btnGroup }) {
  return (
    <Flex
      minH="50vh"
      justify="center"
      align="center"
      direction="column"
      textAlign="center"
    >
      <Heading color="gray.900" px="auto">
        {heading}
      </Heading>
      <Text m="2" maxW="2xl">
        {helpText}
      </Text>
      <Box my={5} p={5} shadow="md" borderWidth="1px" rounded="lg">
        {table}
      </Box>
      {coaches}

      {error && (
        <Alert status="error" mb="4">
          <AlertIcon />
          <AlertTitle mr={2}>{error.title}</AlertTitle>
          <AlertDescription>{error.desc}</AlertDescription>
        </Alert>
      )}
      {btnGroup}

      <Text mt="6" maxW="2xl">
        If you have been kicked out or gave up completely, click{" "}
        <Text as="em" fontWeight="bold">
          Cancel
        </Text>{" "}
        so the above people are released back into the puddle.
      </Text>
    </Flex>
  );
}

function CoachText({ coaches }) {
  const listItems = coaches.map((coach) => {
    return (
      <Box key={coach}>
        <Text>{coach}</Text>
      </Box>
    );
  });

  return (Array.isArray(coaches) && coaches.length) ? (
    <Box mb={5}>
      <Text fontWeight="bold">

        List of acceptable coaches:
      </Text>

      {listItems}



    </Box>
  ) : (null);
}

function CopyHelpText({ details }) {
  const { user } = useContext(UserContext);

  let isInList = false;
  for (const u of details.rankings) {
    if (u.id === user.id) {
      isInList = true;
      break;
    }
  }

  let surpriseText = "";
  if (!isInList && user.buyerId !== user.id) {
    switch (user.status) {
      case ticketStatuses.PURCHASED:
      case ticketStatuses.CONFIRMED:
        surpriseText =
          "Surprised not to see yourself here? Somebody seems to have bought you a ticket already!";
        break;
      case ticketStatuses.LOCKED:
        surpriseText =
          "Surprised not to see yourself here? Somebody has locked you and might be in the process of buying a ticket for you!";
        break;
      default:
        break;
    }
  }

  const detailsCount = Array.isArray(details.rankings) ? details.rankings.length : 0;

  let part1 = "Here are your lucky 6!";
  if (detailsCount < 6) {
    part1 = `Only ${detailsCount} left in the puddle who want tickets! `;
  }
  return (
    <>
      {part1} Copy these details to the ticketing page, submit and click{" "}
      <Text as="em" fontWeight="bold">
        Submitted
      </Text>
      . <br /> {surpriseText}
    </>
  );
}

// Shows a list of details which can be easily copied to the ticketing page
function CopyDetailsPage({
  details,
  loading,
  cancelling,
  onCancel,
  onSubmit,
  error,
}) {
  let layoutProps = { error: error };

  const detailsList = details ? details.rankings : null

  layoutProps.heading = "Details";
  layoutProps.helpText = !loading && <CopyHelpText details={details} />;
  layoutProps.table = <CopyableDetailsTable list={detailsList} loading={loading} />;
  layoutProps.coaches = !loading && <CoachText coaches={details.commonCoaches} />
  layoutProps.btnGroup = (
    <ButtonGroup spacing={4}>
      <Button
        variantColor="red"
        onClick={onCancel}
        isLoading={cancelling}
        isDisabled={loading}
        aria-label="Cancel and go back"
      >
        Cancel
      </Button>
      <Button
        variantColor="green"
        onClick={onSubmit}
        isDisabled={loading || cancelling}
      >
        Submitted
      </Button>
    </ButtonGroup>
  );

  return <DetailsLayout {...layoutProps} />;
}

function ConfirmDetailsPage({
  details,
  cancelling,
  onCancel,
  confirming,
  onConfirm,
  error,
  onBack,
  setPurchaseState,
}) {
  let layoutProps = { error: error };

  layoutProps.heading = "Pay";
  layoutProps.helpText = (
    <>
      <Text as="span" fontWeigth="medium">
        Once you have paid, confirm who you have purchased tickets for by using
        the{" "}
        <Text as="em" fontWeight="bold">
          Y
        </Text>
        es or{" "}
        <Text as="em" fontWeight="bold">
          N
        </Text>
        o buttons.{" "}
      </Text>
      <br />
      <Text as="span" color="gray.600" fontWeight="normal">
        No need to wait for a confirmation email, a succesful payment is good
        enough for now.
      </Text>
    </>
  );
  layoutProps.table = (
    <ConfirmableDetailsTable
      list={details.rankings}
      setConfirmation={setPurchaseState}
      showErrors={Boolean(error)}
    />
  );
  layoutProps.btnGroup = (
    <ButtonGroup spacing={4}>
      <Button
        variantColor="red"
        onClick={onCancel}
        isLoading={cancelling}
        isDisabled={confirming}
      >
        Cancel
      </Button>
      <Button
        variantColor="blue"
        onClick={onBack}
        isDisabled={confirming || cancelling}
      >
        Back
      </Button>
      <Button
        variantColor="green"
        onClick={onConfirm}
        isLoading={confirming}
        loadingText="Confirming"
        isDisabled={cancelling}
      >
        Confirm Purchase
      </Button>
    </ButtonGroup>
  );

  return <DetailsLayout {...layoutProps} />;
}

// Parent to hold details state for the show details and confirm details pages
function DetailsPage({ step, setStep }) {
  const { user, updateUser } = useContext(UserContext);

  const [details, setDetails] = useState(null); //User details to show
  const loading = !details;


  const [error, setError] = useState(false);

  const [cancelling, setCancelling] = useState(false);
  const [confirming, setConfirming] = useState(false);

  // set the purchased property of a user. Reset all to null per default.
  function setPurchaseState(userId = null, value = null) {
    const newDetailsList = [];
    details.rankings.forEach((u) => {
      if (userId === null || u.id === userId) {
        u.purchased = value;
      }
      newDetailsList.push(u);
    });
    setDetails({ ...details, rankings: newDetailsList });
  }

  // Cancel the buying process, confirm all purchases as NO
  function cancel() {
    async function submitData() {
      const list = [];
      for (const item of details.rankings) {
        list.push({ id: item.id, purchased: "n" });
      }
      if ((await confirmPurchase(user.id, user.key, list)) === "ok") {
        const newUser = user;
        newUser.boughtFor = [];
        updateUser(newUser); // resets user bought for
        setStep(steps.WAIT);
      } else {
        // something went wrong
        setError({
          title: "Failed to cancel!",
          desc: " Please check your connection and try again.",
        });
        setCancelling(false);
      }
    }
    setError(false);
    setCancelling(true);
    submitData();
  }

  function confirm() {
    async function submitData() {
      if ((await confirmPurchase(user.id, user.key, details.rankings)) === "ok") {
        setStep(steps.BOUGHT);
        const newUser = user;
        updateUser(newUser); // resets user bought for
      } else {
        setConfirming(false);
        // something went wrong
        setError({
          title: "Failed to Confirm!",
          desc: " Please check your connection and try again.",
        });
      }
    }

    setError(false);

    // check that we have a purchase confirmation for all users
    let noErrors = true;
    details.rankings.forEach((u) => {
      if (!u.purchased) {
        noErrors = false;
      }
    });

    if (noErrors) {
      // submit data once every person has been marked as confirmed Y / N
      setConfirming(true);
      submitData();
    } else {
      setError({
        title: "",
        desc: (
          <Text as="span">
            Please select either{" "}
            <Text as="span" fontWeight="bold">
              Y
            </Text>
            es or
            <Text as="span" fontWeight="bold">
              {" "}
              N
            </Text>
            o for every person above.
          </Text>
        ),
      });
    }
  }

  // Load list of details from api
  useEffect(() => {
    async function fetchData() {
      const result = await getDetails(user.id, user.key);
      if (result[0] === "ok") {
        const details = result[1];
        setDetails(details);
      }
    }
    if (loading) {
      fetchData();
    }
  });

  if (step === steps.DETAILS) {
    return (
      <CopyDetailsPage
        details={details}
        loading={loading}
        cancelling={cancelling}
        error={error}
        onCancel={cancel}
        onSubmit={() => {
          setPurchaseState(); // reset purchase state
          setStep(2);
          setError(null);
        }}
      />
    );
  } else if (step === steps.CONFIRM) {
    return (
      <ConfirmDetailsPage
        details={details}
        cancelling={cancelling}
        onCancel={cancel}
        confirming={confirming}
        onConfirm={confirm}
        error={error}
        onBack={() => {
          setError(false);
          setStep(steps.DETAILS);
        }}
        setPurchaseState={setPurchaseState}
      />
    );
  }

  return null;
}

export default DetailsPage;
